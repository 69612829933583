
import {currency, slash} from "@/filters/utils";
import {
  Traceability,
  TraceabilityTypes,
} from "@/store/modules/traceability/traceability.types";
import {DefaultState, Pagination} from "@/types/types";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ImageSelector from "@/components/utils/PhotoSelect.vue";
import DatePicker from "@/components/utils/DatePicker.vue";

import QRious from "qrious";

const qr: any = new QRious({
  background: 'white',
  backgroundAlpha: 0.0,
  foreground: 'black',
  foregroundAlpha: 1,
  level: 'H',
  padding: 25,
  size: 500,
});

const traceability = namespace(TraceabilityTypes.MODULE);

@Component({
  components: {
    DatePicker, ImageSelector,
    NoData: () => import("@/components/utils/NoData.vue"),
  },
  filters: {
    currency,
    slash,
  },
})
export default class TraceabilitiesView extends Vue {
  @Prop({default: true}) pagination?: boolean;
  @Prop({default: ""}) query?: string;

  @traceability.State("traceabilities")
  public traceabilities!: Traceability[];

  @traceability.State("add")
  public addTraceability!: DefaultState;

  @traceability.State("pages")
  public pageCount!: number;

  @traceability.State("loadingTraceability")
  public loadingTraceabilities!: boolean;

  @traceability.Mutation(TraceabilityTypes.SET_ADD_TRACEABILITY_DIALOG)
  public setAddTraceability!: (addTraceability: boolean) => void;

  @traceability.Action(TraceabilityTypes.LOAD_TRACEABILITIES)
  public loadTraceabilities!: (pagination: Pagination) => void;

  public page = 1;

  @Prop({required: true, default: 10}) public pageSize!: number;

  public headers = [
    {
      text: "FARMER CODE",
      sortable: true,
      value: "farmer_code",
    },
    {
      text: "NAME",
      sortable: true,
      value: "full_name",
    },
    {
      text: "GENDER",
      sortable: true,
      value: "gender",
    },
    {
      text: "DATE OF REGISTRATION",
      sortable: true,
      value: "date_of_registration",
      align: "center",
    },
    {
      text: "DATE OF FIRST INSPECTION",
      sortable: true,
      value: "date_of_first_inspection",
      align: "center",
    },
    {
      text: "DATE OF LAST INSPECTION",
      sortable: true,
      value: "date_of_last_inspection",
      align: "center",
    },
    {
      text: "ORGANIC STATUS",
      sortable: true,
      value: "is_organic_compliant",
      align: "center",
    },
    {
      text: "GPS COORDINATES",
      sortable: true,
      value: "gps_coordinates",
      align: "center",
    },
    {
      text: "ALTITUDE",
      sortable: true,
      value: "altitude",
      align: "center",
    },
    {
      text: "TOTAL LAND ACREAGE",
      sortable: true,
      value: "total_land_acreage",
      align: "center",
    },
    {
      text: "TOTAL YIELD",
      sortable: true,
      value: "total_yield",
      align: "center",
    },
    {
      text: "ACTIONS",
      sortable: true,
      value: "actions",
      align: "center",
    },
  ];

  public qrCodeData: QRCodeData = {
    dialog: false
  }

  public qrCode = "";

  async openViewQRCode(data: Traceability): Promise<void> {

    this.qrCodeData.data = data;
    this.qrCodeData.dialog = true;

    qr.set({
      value: `${window.location.origin}/coffee/${data.id}`
    })

    this.qrCode = qr.toDataURL();
  }

  closeAddTraceabilityDialog(): void {
    this.setAddTraceability(false);
  }

  openAddTraceabilityDialog(): void {
    this.setAddTraceability(true);
  }

  @Watch("pageSize")
  handlePageSizeChange(): void {
    this.page = 1;
    this.queryTraceabilities();
  }

  @Watch("page")
  handlePageChange(): void {
    this.queryTraceabilities();
  }

  queryTraceabilities(): void {
    this.loadTraceabilities({
      page: this.page,
      limit: this.pageSize,
      query: this.query,
    });
  }

  mounted(): void {
    if (this.traceabilities.length == 0) this.queryTraceabilities();
  }
}

type QRCodeData = {
  dialog: boolean;
  data?: Traceability;
}
